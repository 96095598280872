/* Font Import */
@import "./css/font/inter.css";
@import "./css/font/Manrope.css";

:root {
  --green: #024b12;
  --greenFirst: #248711;
  --greenSec: #bedbb6;
  --greenThr: #007429;
  --greenFor: #009008;
  --greenFive: #32ff17;
  --black: #000;
  --white: #fff;
  --grey: #7d7d7d;
  --greyDark: #575757;
  --greySec: #9a9a9a;
  --red: #ff0000;
  --yellow: #ffca0f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background-color: #f8f4ea !important;
}

.container {
  max-width: 1600px !important;
  margin: auto;
  padding: 0 16px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--black) !important;
}

.input-field input:-webkit-autofill,
.input-field input:-webkit-autofill:hover,
.input-field input:-webkit-autofill:focus,
.input-field input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--white) !important;
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-size: 42px !important;
  font-weight: 800 !important;
  font-family: "Manrope", sans-serif;
}

p {
  font-size: 20px;
  color: var(--greyDark);
}

h5 {
  font-weight: 800 !important;
  color: var(--grey) !important;
}

.btns {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 12px;
  white-space: nowrap;
}

.dashboard-inputs {
  border: 1px solid var(--black);
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.dashboard-inputs input {
  padding: 0 !important;
  color: var(--black) !important;
}
.dashboard-inputs input::placeholder {
  color: var(--black) !important;
}
.credits {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 18px;
  border: 2px solid #1e7c0e;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(17, 136, 42, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* __________________________________ */

/* Header */
.header {
  background: var(--greenSec);
}
nav {
  padding: 28px 0;
}
.register {
  position: relative;
  border: 1px solid transparent;
  background: var(--greenSec);
  background-clip: padding-box;
  border-radius: 10px;
  padding: 16px;
  width: 120px;
  font-weight: 600;
}
.register::after {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(90deg, #4cd022 -0.69%, #1e7d0e 100.69%);
  z-index: -1;
  border-radius: 10px;
}
.login {
  padding: 18px 16px;
  border: none;
  border-right: 2px solid #32ff17;
  border-radius: 10px;
  background-image: linear-gradient(to right, #4cd022, #1e7d0e);
  color: var(--white);
  width: 120px;
}
/* __________________________________________ */

/* Home Section */

/* ________Hero________ */
.home-section {
  background-color: var(--greenSec);
  max-height: 600px;
  min-height: 464px;
  height: 100%;
}
.hero-content-bg {
  background-image: url(assets/images/background/hero.svg);
  background-repeat: no-repeat;
  background-position: right 20px;
}

/* ________Experience________ */
.experience-section {
  padding: 120px 0;
}
.experience-titles {
  padding-top: 220px;
  padding-bottom: 80px;
  text-align: center;
}
.goal-section h3 {
  color: #063b39;
  font-weight: 700;
}
.booking-button {
  background-color: var(--greenThr);
  padding: 20px;
  color: var(--white);
  font-weight: 600;
  border: none;
  border-radius: 4px;
  max-width: 300px;
  width: 100%;
}

/* Testimonial */
.testimonial-section {
  padding-top: 50px;
  background-color: var(--greenFirst);
}
.testimonial-section h6 {
  color: var(--white);
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.testimonial-section p {
  color: var(--white);
  text-align: center;
}

/* Swiper */
.swiper {
  width: 100%;
  height: 100%;
  max-width: 991px;
  margin: auto;
  padding-bottom: 50px !important;
}
.swiper-slide {
  padding: 30px 20px 30px 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.swiper-pagination-bullet-active {
  background-color: var(--white) !important;
}
.testi-user {
  height: 50px;
  width: 50px;
}

/* Dashboard */
.offcanvas-header .btn-close {
  background-image: url("./assets/images/icon/close.svg") !important;
  height: 30px;
  width: 30px;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 1 !important;
  background-color: #00350c;
  border-radius: 100%;
}
.menu-tab {
  position: absolute;
  top: 69px;
  right: 20px;
  cursor: pointer;
}
.tab-view {
  background: linear-gradient(to right, #00350c, #025314);
}
.slider-tabs {
  background: #00350c;
}
.tab-active {
  background: linear-gradient(
    360deg,
    #00350c -19.09%,
    #ffca0f 99.98%
  ) !important;
}
.tab-buttons {
  white-space: nowrap;
  cursor: pointer;
  position: sticky;
}
.tab-background {
  position: relative;
  width: 100%;
}
.tab-image::before {
  background-image: url(assets/images/icon/active-tl.svg) !important;
  opacity: 1 !important;
}
.tab-image::after {
  background-image: url(assets/images/icon/active-rb.svg) !important;
  opacity: 1 !important;
}
.tab-background::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  background-image: url(assets/images/icon/top-left.svg);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}
.sidebar-bg::before,
.sidebar-bg::after {
  opacity: 1 !important;
}
.tab-background::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-image: url(assets/images/icon/right-bottom.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}
.tab-buttons .active {
  background: linear-gradient(
    0deg,
    rgba(255, 202, 15, 0) -19.09%,
    #ffca0f 99.98%
  );
}
.tabBtns {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  cursor: pointer;
  position: relative;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(
    0deg,
    rgba(255, 202, 15, 0) -19.09%,
    rgba(255, 202, 15, 0.1) 99.98%
  );
  border: 1px solid rgba(255, 202, 15, 10%);
}

.modal-header {
  background: #025314;
  color: var(--white) !important;
  display: flex;
  justify-content: space-between;
}
.modal-content {
  border-radius: 12px !important;
  overflow: hidden !important;
}
.dailog-card {
  background: #153620;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--yellow);
  color: #d9d9d9;
}

.btn-close {
  background-image: url("./assets/images/icon/close.svg") !important;
  height: 50px;
  width: 50px;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 1 !important ;
}

/* My Course */
.pagination-part select {
  border: 0;
}
.pagination-part select:focus {
  outline: 0;
}
.input-field {
  white-space: nowrap;
  position: relative;
  border: 1.9px solid transparent;
  background: #324e3b;
  background-clip: padding-box;
  border-radius: 10px;
  padding: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.input-field::after {
  content: "";
  position: absolute;
  top: -1.9px;
  bottom: -2.9px;
  left: -1.9px;
  right: -1.9px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 100%), #486150);
  z-index: -1;
  border-radius: 10px;
}
input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0 10px;
  color: var(--white);
  font-weight: 400;
}
textarea {
  background: transparent;
  border: none;
  width: 100%;
  color: var(--white);
  height: 90px !important;
}
textarea::placeholder {
  color: var(--white);
}
textarea:focus {
  outline: 0;
}
input:focus {
  outline: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.titels {
  white-space: nowrap;
  position: relative;
  border: 1.9px solid transparent;
  background: var(--greySec);
  background-clip: padding-box;
  border-radius: 10px;
  padding: 12px 20px;
  font-weight: 600;
  color: var(--white);
  text-align: center;
}
.titels::after {
  content: "";
  position: absolute;
  top: -1.9px;
  bottom: -1.9px;
  left: -1.9px;
  right: -1.9px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 100%),
    rgba(255, 255, 255, 10%)
  );
  z-index: -1;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    #ffca0f -0.69%,
    rgba(255, 223, 108, 0.84) 100.69%
  );
}
.yes-no {
  width: 35px;
  height: auto;
  text-align: center;
}
.btns-d {
  max-width: 147px;
  width: 100%;
  min-width: 130px;
  margin: 0 !important;
  padding: 12px 20px !important;
  color: var(--black);
  font-weight: 600;
}
.cancel {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 31%),
    rgba(255, 202, 15, 100%)
  );
  border: 2px solid var(--red);
}
.add {
  background: linear-gradient(
    to bottom,
    rgba(255, 202, 15, 100%),
    rgba(255, 202, 15, 45%)
  );
  border: none;
  padding: 13px 20px !important;
}
.edit::before {
  content: "";
  position: absolute;
  top: 9px;
  left: 9px;
  background-image: url("./assets/images/icon/top-left.svg");
  background-position: top left;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.edit::after {
  content: "";
  position: absolute;
  right: 9px;
  bottom: 9px;
  background-image: url("./assets/images/icon/right-bottom.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 100%;
  width: 100%;
}
.hole-plus {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  gap: 30px;
}
.hole-plus span {
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hole-plus img {
  background-color: white;
  padding: 10px 7px;
  border-radius: 5px;
  cursor: pointer;
}
.hole-plus img:first-child {
  transform: rotate(270deg);
}
.hole-plus img:last-child {
  transform: rotate(90deg);
}
.hole-remove {
  width: fit-content;
}
.hole {
  position: relative;
  border: none !important;
  background: #215e2e;
  background-clip: padding-box;
  border-radius: 10px;
  font-weight: 600;
}
.hole::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1.3px;
  left: -1px;
  background: linear-gradient(
    90deg,
    #ffca0f -0.69%,
    rgba(255, 223, 108, 0.84) 100.69%
  );
  z-index: -1;
  border-radius: 10px;
}

.add-course {
  border: 1px solid var(--greenFive);
  background: linear-gradient(0deg, #215e2e, #215e2e),
    linear-gradient(90deg, #32ff17 -0.69%, #4fff61 100.69%);
  color: var(--white);
}

.add-promo {
  border: 1px solid #d0d0d0;
  background: #ffffff;
  color: var(--greyDark);
  font-weight: 600;
}
.add-course span {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
}

.my-desk-modal .modal-dialog {
  max-width: 700px !important;
}

/* Table */
.score-data {
  box-shadow: 0px 13px 13px 0px rgba(0, 0, 0, 8%) !important;
}
.table {
  margin: 0 !important;
  white-space: nowrap;
}
.table thead {
  background: linear-gradient(90deg, #00350c 5.14%, #025314 86.23%),
    linear-gradient(90deg, #32ff17 -0.69%, #4fff61 100.69%) !important;

  color: var(--white);
}
.table thead tr th {
  background: none;
  color: white;
}
.table thead tr th {
  padding: 15px 12px;
}
.table tbody tr {
  border-color: #6c6c6c !important;
}
.table tbody tr td {
  padding: 12px 12px;
  line-height: 40px;
}
.table thead tr th:first-child,
.table tbody tr td:first-child {
  padding-left: 30px;
  text-align: left;
}
.score-data .table tbody tr td,
.score-data .table thead tr th {
  padding: 10px;
  border-bottom: 0 !important;
  border-top: 0.7px solid;
  border-left: 0.7px solid;
  border-right: 0.7px solid;
  border-color: rgba(27, 27, 27, 30%);
}
.score-data .table tbody tr td:first-child,
.score-data .table thead tr th:first-child {
  border-left: 0;
}
.action-btn {
  height: 40px;
  width: 40px;
  background: rgba(30, 124, 14, 12%);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* My Deck */
.players-select span {
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 10px;
  color: var(--black);
  line-height: 0;
}

.players-select .active {
  position: relative;
  border: 2px solid transparent;
  background: #324e3b;
  background-clip: padding-box;
  border-radius: 10px;
  height: 33px;
  width: 33px;
  color: var(--white);
}

.players-select .active::after {
  content: "";
  position: absolute;
  top: -1.9px;
  bottom: -2.9px;
  left: -1.9px;
  right: -1.9px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 100%), #486150);
  z-index: -1;
  border-radius: 10px;
}

/* Profile */
.profile-input {
  margin-bottom: 20px;
}
.profile-input p {
  padding: 15px 35px;
  background: linear-gradient(to right, #00350c, #025314);
  color: white;
  border-right: 1px solid var(--greenFive);
  border-radius: 10px 10px 0 0;
}
.profile-input input {
  background-color: rgba(157, 157, 157, 52%);
  padding: 12px 35px;
  border-radius: 10px;
  color: var(--black);
  font-size: 18px;
}
.profile-input input:focus {
  outline: 0;
}

.sub-img {
  position: relative;
  cursor: pointer;
}
.sub-img::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  background-image: url(assets/images/icon/active-tl.svg);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.sub-img::after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-image: url(assets/images/icon/active-rb.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.subscrip {
  position: relative;
  background: linear-gradient(to top, #00350c, #ffca0f);
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  border: 0 solid transparent;
  color: white;
  border-radius: 10px;
}
.subscrip::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(
    0deg,
    rgba(255, 223, 108, 0.84) 0.62%,
    #ffca0f 99.98%
  );
  z-index: -1;
  border-radius: 10px;
}
.logout {
  border: 1px solid #ff0000;
  color: #ff0000;
  width: 90% !important;
  margin: auto;
}

.subscription {
  border: 2px solid #ffca0f;
  background: linear-gradient(
    180deg,
    rgba(50, 255, 23, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  color: var(--white);
  width: 188px;
}

/* ________My_Courses________ */
@media screen and (max-width: 1400px) {
  .experience-section {
    padding: 80px 0;
  }
}
@media screen and (max-width: 1200px) {
  .goal-section p {
    font-size: 16px;
  }
  .experience-titles {
    padding: 100px 0 !important;
  }
}
@media screen and (max-width: 1140px) {
  .experience-section {
    padding: 50px 0;
  }
  .experience-titles {
    padding: 50px 0 !important;
  }
}
@media screen and (max-width: 991px) {
  h1 {
    font-size: 30px !important;
  }
  p {
    font-size: 16px;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px !important;
  }
  .booking-button {
    padding: 10px;
    border-radius: 7px;
    max-width: auto;
    width: auto;
  }
  .experience-titles {
    padding: 0 0 50px 0 !important;
  }
  .dashboard-inputs {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 569px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 18px !important;
  }
  p {
    font-size: 14px !important;
  }
  .logo {
    width: 50%;
    height: 50%;
  }
  .login,
  .register {
    padding: 9px 10px;
    width: 68px;
    height: 36px;
    font-size: 12px;
  }
  .home-section {
    max-height: auto;
    min-height: auto;
    height: 100%;
  }
  .menu-tab {
    position: absolute;
    top: 43px;
    right: 20px;
    cursor: pointer;
  }
  .input-field {
    padding: 12px;
    font-size: 14px;
  }

  .subscription-modals .input-field {
    font-size: 14px !important;
  }
  .subscription {
    width: 100% !important;
    max-width: 100px;
    min-width: 100px !important;
    padding: 10px !important;
    font-size: 12px;
  }
  .hole-plus {
    gap: 5px;
  }
}

.rdt_TableHeader {
  background-color: transparent !important;
  display: none !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.error-text {
  color: #c9353e;
  font-size: 14px;
  margin-top: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.suggestion-list {
  position: absolute;
  top: 85px;
  left: 50%;
  z-index: 1000;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  border-radius: 10px;
  overflow-y: hidden;
  width: 87%;
  padding-left: 0;
  transform: translate(-50%);
  background-color: #bedbb6;
  color: #000;
}

.dropdown-hover-class:hover {
  background-color: #00350c27;
}

.pagination {
  background-color: transparent;
}

.mb-8 {
  margin-bottom: 80px;
}

.disable {
  opacity: 50%;
}

.paginaion-button {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.paginaion-button-hover {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.paginaion-button-hover:hover {
  background-color: #73737338;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
}

.promo-code-list {
  position: absolute;
  left: 50%;
  z-index: 1000;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  border-radius: 10px;
  overflow-y: hidden;
  width: 87%;
  padding-left: 0;
  transform: translate(-50%);
  background-color: #bedbb6;
  color: #000;
}
