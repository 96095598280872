:root {
  --main-blue: #71b7e6;
  --main-purple: #9b59b6;
  --main-grey: #ccc;
  --sub-grey: #d9d9d9;
}

/* container and form */
.login-forms {
  background-image: url(../../assets/images/background/LoginBG.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.login-main {
  max-height: 700px !important;
  min-height: 100px !important;
  height: 100% !important;
  max-width: 800px;
  width: 100%;
  margin: auto;
  background: rgba(255, 255, 255, 0.3);
  padding: 25px 30px;
  border-radius: 10px;
}
.container .title {
  font-size: 25px;
  font-weight: 600;
  color: white;
  position: relative;
  width: fit-content;
}

.container .title::before {
  content: "";
  position: absolute;
  height: 3.5px;
  width: 50%;
  background: linear-gradient(to right, #4cd022, #1e7d0e);
  left: 0;
  bottom: -5px;
}

.container form .user__details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
/* inside the form user details */
form .user__details .input__box {
  width: calc(100% / 2 - 20px);
  margin-bottom: 15px;
}
form .user__details .otp-input input {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 20px;
  padding: 0 !important;
}
form .user__details .input__box span {
  color: white;
  font-weight: 600;
}
.font-600 {
  font-weight: 600;
}
.forgot-password span,
.resend-otp a {
  font-weight: 600 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  color: #324e3b !important;
}

@media only screen and (max-width: 584px) {
  .container {
    max-width: 100%;
  }

  form .user__details .input__box {
    margin-bottom: 15px;
    width: 100%;
  }

  form .gender__details .category {
    width: 100%;
  }

  .container form .user__details {
    max-height: 300px;
    overflow-y: scroll;
  }

  .user__details::-webkit-scrollbar {
    width: 0;
  }
}

.inputStyle {
  white-space: nowrap;
  position: sticky;
  border: 1.9px solid transparent;
  background: #324e3b;
  background-clip: padding-box;
  border-radius: 10px;
  padding: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 50px !important;
  height: 50px !important;
  text-align: center;
  font-size: 20px;
  padding: 0 !important;
}
